// Usage: This file is used to configure the dinamic rendering for the system.

type SystemId =
  | 'inurba-tampere'
  | 'inurba-rouen'
  | 'inurba-gdansk'
  | 'oslobysykkel'
  | 'trondheim'
  | 'bergen-city-bike'
  | 'milan-bikemi'
  | 'verona-bike'
  | 'london-greenquarter'
  | 'helsinki-citybike';

export interface DinamicRenderingConfig {
  systemId: SystemId;
  systemName: string;
  showHeader: boolean;
  showFooter: boolean;
  showGetTheAppVideo: boolean;
  appIconPadding?: boolean;
  showTariffs?: boolean;
}

export const dinamicRenderingConfig: DinamicRenderingConfig[] = [
  {
    systemId: 'inurba-tampere',
    systemName: 'Tampereen kaupunkipyörät',
    showHeader: false,
    showFooter: false,
    showGetTheAppVideo: false,
    appIconPadding: true,
    showTariffs: true
  },
  {
    systemId: 'inurba-rouen',
    systemName: 'LOVÉLO libre-service',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: false,
    showTariffs: true
  },
  {
    systemId: 'inurba-gdansk',
    systemName: 'MEVO',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: false,
    showTariffs: true
  },
  {
    systemId: 'oslobysykkel',
    systemName: 'Oslo Bysykkel',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: true,
    showTariffs: false
  },
  {
    systemId: 'trondheim',
    systemName: 'Trondheim Bysykkel',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: true,
    showTariffs: true
  },
  {
    systemId: 'bergen-city-bike',
    systemName: 'Bergen Bysykkel',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: true,
    showTariffs: true
  },
  {
    systemId: 'milan-bikemi',
    systemName: 'bikeMi',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: false,
    showTariffs: true
  },
  {
    systemId: 'verona-bike',
    systemName: 'Verona Bike',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: true,
    showTariffs: true
  },
  {
    systemId: 'london-greenquarter',
    systemName: 'London Greenquarter',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: false,
    appIconPadding: false,
    showTariffs: true
  },
  {
    systemId: 'helsinki-citybike',
    systemName: 'Helsinki Citybike',
    showHeader: true,
    showFooter: true,
    showGetTheAppVideo: true,
    appIconPadding: false,
    showTariffs: true
  }
];
